<template>
  <div
    id="insights"
    class="px-6 py-4 d-flex flex-column fill-height align-stretch justify-center"
  >
    <v-toolbar 
      flat
      tile
      :height="breakpoint('md') ? '120' : '96'"
      color="transparent"
      class="px-2 pr-4 pr-md-2 py-0 align-self-center align-center flex-shrink-0 flex-grow-0"
    >
      <v-toolbar-title class="text-h4 text-center font-weight-light px-4">
        Insights
      </v-toolbar-title>
    </v-toolbar>

    <v-card 
      :loading="controller.prompt.loading"
      elevation="8"
      class="prompt-container align-self-center mb-8"
    >
      <v-textarea
        v-model="controller.prompt.value"
        :disabled="controller.prompt.loading||controller.prompt.disabled"
        :append-icon="icons.send"
        :placeholder="controller.prompt.label"
        hide-details
        solo
        flat
        rows="1"
        auto-grow
        class="prompt control ma-2"
        @keydown.enter.exact.prevent
        @keyup.enter.exact="get(controller.prompt, controller.prompt.value)"
        @click:append="get(controller.prompt, controller.prompt.value)"
      />
    </v-card>

    <div class="insights-container fill-width d-flex flex-wrap justify-center pb-12">
      <div
        v-for="insight in insights"
        :key="insight.id"
        class="insight-container flex-shrink-0 ma-4"
      >
        <v-hover v-slot="{ hover }">
          <data-overview-card 
            :title="insight.prompt"
            :number="insight.number"
            :chart="insight.chart"
            :loading="insight.loading"
            :color="insight.color"
            overflow
            height="100%"
            max-height="248px"
            class="insight overflow-visible"
            @click="get(insight, null, insight.id)"
          >
            <template v-slot:content>
              <v-card-actions 
                v-if="insight.data==null"
                class="px-8 pb-8"
              >
                <v-btn
                  icon
                  :loading="insight.loading"
                  @click.stop="get(insight, null, insight.id)"
                >
                  <v-icon>{{ icons.refresh }}</v-icon>
                </v-btn>
              </v-card-actions>
            </template>
            <template v-slot:actions>
              <div
                v-show="insight.data!=null"
                class="score control"
              >
                <v-btn
                  fab
                  small
                  :disabled="insight.loading"
                  color="white"
                  :class="[(insight.score==controller.insights.score.bad ? 'error' : 'grey')+'--text']"
                  :elevation="insight.score==0||insight.score==controller.insights.score.bad ? 6 : 0"
                  class="score-btn mx-2"
                  @click.stop="score(insight, insight.id_execution, controller.insights.score.bad)"
                >
                  <v-icon size="20">{{ icons.bad }}</v-icon>
                </v-btn>
                <v-btn
                  fab
                  small
                  :disabled="insight.loading"
                  color="white"
                  :elevation="insight.score==0||insight.score==controller.insights.score.good ? 6 : 0"
                  :class="[(insight.score==controller.insights.score.good ? 'primary' : 'grey')+'--text']"
                  class="score-btn mx-2"
                  @click.stop="score(insight, insight.id_execution, controller.insights.score.good)"
                >
                  <v-icon size="20">{{ icons.good }}</v-icon>
                </v-btn>
              </div>
            </template>
          </data-overview-card>
        </v-hover>
      </div>
    </div>
    
    <v-overlay 
      :value="controller.insights.loading"
      color="white"
      absolute
      opacity=".8"
      class="loading-overlay text-center"
    >
      <loading class="loading mb-4" />
      <span class="d-block text-overline grey--text text--lighten-1">
        Carregando
      </span>
    </v-overlay>
  </div>
</template>

<style lang="scss">

  #insights {
    // .insights-container
    .prompt-container {
      position: sticky;
      top: 96px;
      width: 50vw;
      z-index: 2;
    }
    .insight-container {
      width: calc(40% - 32px);

      .score.control {
        position: absolute;
        bottom: -20px;
        right: 16px;
      }
    }
    .prompt.control .v-label {
      top: 14px !important;
    }
  }
  
</style>

<script>

  // Icons
  import { mdiRefresh, mdiSend, mdiThumbDown, mdiThumbUp } from '@mdi/js'
  import { sync } from 'vuex-pathify'
  import services from '@/services'
  import $api from '@/api/insights';


  export default {
    name: 'Insights',

    props: {
      disabled: {
        type: Boolean,
        default: false
      },
    },

    data: () => ({
      icons: {
        send: mdiSend,
        refresh: mdiRefresh,
        good: mdiThumbUp,
        bad: mdiThumbDown,
      },
      controller: {
        prompt: {
          value: null,
          label: 'Faça uma pergunta',
          loading: false,
          disabled: false,
          rules: [v => !!v && v!='' || 'Faça uma pergunta'],
        },
        insights: {
          value: null,
          data: {},
          score: {
            good: 1,
            bad: -1
          },
          loading: false,
          updated: false,
        }
      },
      loading: true,
      updated: true,
    }),

    computed: {
      user: sync('user/data'),
      toast: sync('app/toast'),

      insights () {
        const data = this.controller.insights.data;
        return _.orderBy(data, ['createdAt'], ['desc']);
      }

    },

    watch: {
      
    },

    methods: {
      ...services,

      select (id) {
        this.controller.insights.value = id;
      },

      process (insight, updated=false) {
        let number = null;
        let hint = null;
        let chart = {
          type: null,
          data: null,
          length: 0,
        }
        let color = 'primary';
        let format = '0,0[.]0[0] a';
        const keywords = [
          { 
            key: 'impactos', 
            text: 'Impactos'
          },
          {
            key: 'impressoes', 
            text: 'Impressões'
          },
          {
            key: 'bairro', 
            text: 'Bairro'
          }
        ];
        const parse = value => _.isString(value) ? _.reduce(keywords, (text, k) => {
          text = text!==value ? text : new RegExp(k.key, 'gi').test(value) ? k.text : text;
          return text;
        }, value) : value;

        console.log(insight);

        if (!!insight.data) {
          let columns = _.reduce(_.sample(insight.data), (cols, value, key) => {
            const type = _.isNumber(value) ? 'value' : 'text';
            cols[type].push({
              type,
              key,
              text: _.capitalize(parse(key)),
            });
            return cols;
          }, { value: [], text: [] });
          number = _.reduce(insight.data, (total, row) => {
            const value = _.find(row, v => _.isNumber(v));
            return total+value;
          }, 0);
          // hint = _.find(keywords, k => insight.prompt.indexOf(k)>=0) || parse(columns.value);
          
          // multiple/single value columns
          if (_.size(columns.value)>1) {
            chart.type = 'SimpleTable';
            chart.headers = _.reduce([...columns.text, ...columns.value], (headers, col) => {
              headers.push({
                ...col,
                align: col.type=='value' ? 'right' : 'left'
              });
              return headers;
            }, []);
            chart.data = _.orderBy(insight.data, [_.last(columns.value).key], ['desc']);

          }else{
            columns = _.mapValues(columns, v => _.isEmpty(v) ? null : _.first(v).key);
            if (_.size(insight.data)>1) {
              const max = _.max(_.map(insight.data, columns.value));

              if (_.size(insight.data)==2) {
                // Use simple column chart type
                number = _.map(insight.data, (row) => {
                  const value = row[columns.value];
                  const text = row[columns.text];
                  return {
                    text,
                    value: (number>0 ? _.round((value/max)*100, 1) : 50) + '%',
                    format: '0.00 a',
                    hint: {
                      text: this.$numeral(value).format(format) + ' ' + text,
                      color: 'grey'
                    }
                  }
                });
              }
              if (_.size(insight.data)==2) {
                chart.type = 'SimpleColumn';
                color = ['accent', 'primary'];
                insight.data = _.orderBy(insight.data, [columns.value], ['desc']);
                chart.data = _.map(insight.data, row => {
                  const { [columns.text]: text, [columns.value]: value } = row;
                  return { text: this.$numeral(value).format(format) + ' ' + text, value: value + '%' }
                });
              }else {
                if (_.size(insight.data)<=7&&columns.text.indexOf('dia')>=0) {
                  chart.type = 'SimpleColumn';
                }else{
                  chart.type = 'SimpleRow';
                  insight.data = _.orderBy(insight.data, [columns.value], ['desc']);
                }
                chart.data = _.map(insight.data, n => {
                  const text = n[columns.text];
                  const value = n[columns.value];
                  return { 
                    text: text + ': ' + this.$numeral(value).format(format), 
                    value: number==0 ? 0 : _.round((value/max)*100, 1) + '%',
                  }
                });
              }
            }
          }
        }
        
        insight.color = color;
        return { 
          ...insight,
          number: chart.type=='SimpleTable' ? null : _.isNumber(number) ? {
            value: number,
            format: '0.00 a',
            hint: {
              text: hint,
              color: 'grey'
            }
          } : number,
          chart,
          color,
          loading: false,
          updated,
        };
      },
      
      list (controller=this) {
        controller.loading = true;

        $api.list(
          this,
          this.user.auth.token, 
          (data) => {
            _.each(data, insight => {
              insight = this.process(this.format(insight));
              this.$set(this.controller.insights.data, insight.id, insight);
            });
            controller.updated = true;
          },
          (error) => {
            if (error.response.status!=404) {
              const msg = 'Aguardando resposta da nuvem...';
              this.handleError(error, msg, [
                true,
                msg,
                7000,
                false
              ]);
              setTimeout(($) => $.list(), 5000, this);
            }
          },
          () => {
            controller.loading = false;
          }
        )
      },

      get (controller=this, prompt=null, id=null) {
        if (prompt==null&&id==null) return;
        controller.loading = true;
        controller.updated = false;

        $api.get(
          this,
          this.user.auth.token, 
          prompt, 
          id,
          (insight) => {
            insight = this.process(this.format(insight), true);
            this.$set(this.controller.insights.data, insight.id, insight);
            // this.select(insight.id);
            controller.updated = true;
            if (!!prompt) this.controller.prompt.value = null;
          },
          (error) => {
            if (error.response.status!=404) {
              const msg = 'Aguardando resposta da nuvem...';
              this.handleError(error, msg, [
                true,
                msg,
                7000,
                false
              ]);
              // setTimeout(($) => $.get(controller, prompt, id), 5000, $);
            }
          },
          () => {
            controller.loading = false;
          }
        )
      },

      score (controller=this, id=null, score=1) {
        if (id==null) return;
        controller.loading = true;
        controller.updated = false;

        $api.feedback(
          this,
          this.user.auth.token, 
          id,
          score,
          (data) => {
            controller.score = score;
            this.toggleToast(
              true,
              'Obrigado pelo feedback!',
              5000,
              false,
            );
            controller.updated = true;
          },
          (error) => {
            if (error.response.status!=404) {
              const msg = 'Não foi possível salvar o seu feedback. Tente novamente, por favor.';
              this.handleError(error, msg, [
                true,
                msg,
                7000,
                false
              ]);
              // setTimeout(($) => $.get(controller, prompt, id), 5000, $);
            }
          },
          () => {
            controller.loading = false;
          }
        )
      },

      feedback () {
        // TODO: send feedback
      },

      format (insight) {
        const { 
          id_insight_usuario: id, 
          dt_pedido: createdAt,
          pedido: prompt,
          data=null,
          execution={}
        } = insight;
        const {
          id_insight_execucao: id_execution=null,
          dt_execucao: ranAt=Date.now(),
          erro_execucao: error=null,
          score=0
        } = execution;

        return {
          id,
          prompt,
          createdAt,
          data,
          id_execution,
          ranAt,
          error,
          score
        }
      },
    },

    mounted () {
      // TODO: get list of user insights
      this.list(this.controller.insights);
    },

    components: {
      // LocationList: () => import("@/components/campaigns/LocationList"),
      DataOverviewCard: () => import("@/components/DataOverviewCard"),
      Loading: () => import('@/components/IconLoading'),
    },

  }
</script>
